/**
 * [View all supported countries](https://github.com/sumup-oss/intl-js/blob/main/src/data/currencies.ts).
 */
export enum EnumCurrency {
  GTQ = 'GTQ', // Quetzal
  USD = 'USD', // Dolar
  EUR = 'EUR' // Euro
}

export enum EnumIBANCurrency {
  GTQ = 1, // Quetzal
  USD = 2, // Dolar
  EUR = 28 // Euro
}

export const DEFAULT_CURRENCY = {
  code: EnumCurrency.GTQ,
  symbol: 'Q',
  name: 'QUETZAL',
  plural: 'QUETZALES'
}

export const CATALOG_CURRENCIES = [
  {
    code: EnumCurrency.GTQ,
    dbOld: '6580613157e3e1ad0cc36471',
    name: 'QUETZALES',
    other: {
      default: 'QUETZAL',
      feic: 'QUETZALES'
    },
    symbol: {
      default: 'Q',
      feic: 'GTQ'
    }
  },
  {
    code: EnumCurrency.USD,
    dbOld: '6580f432a734517993de3258',
    name: 'DOLARES',
    other: {
      default: 'DOLAR',
      feic: 'DOLARES'
    },
    symbol: {
      default: '$',
      feic: 'USD'
    }
  }
  // {
  //   code: 'EUR',
  //   name: 'EUROS',
  //   other: {
  //     default: 'EURO',
  //     feic: 'EUROS'
  //   },
  //   symbol: {
  //     default: '€',
  //     feic: 'EUR'
  //   }
  // }
]
