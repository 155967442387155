import { scan } from 'react-scan' // import this BEFORE react
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './app'
import { disableReactDevTools } from '@fvilers/disable-react-devtools'

if (typeof window !== 'undefined') {
  scan({
    enabled: true,
    log: true // logs render info to console (default: false)
  })
}

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools()
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
