import axios from 'axios'

export default axios.create({
  baseURL: import.meta.env.VITE_API_URL_NEST
})

export const axiosPrivate = axios.create({
  baseURL: import.meta.env.VITE_API_URL_NEST,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

export const axiosRenap = axios.create({
  baseURL: import.meta.env.VITE_API_URL_RENAP,
  headers: {
    'Content-Type': 'application/json'
  }
})
