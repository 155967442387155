import { DEFAULT_CURRENCY } from '@/catalogs/currencies'
import { LOCALE_CURRENCY } from '@/config'
import { getPropertyByPath } from '@/utils'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { validate } from 'uuid'

export function cn (...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function searchItemOnArray (array: any[], key: string | string[], value: string | undefined): Record<string, any> | undefined {
  if (!value) return undefined
  return array?.find(item => getPropertyByPath(item, key, 'UNDEFINED') === value) ?? undefined
}

export function arrayIncludesAny (array1: any[], array2: any[]): boolean {
  return array1.some(element => array2.includes(element))
}

export const nitValidation = (nit: string): boolean => {
  if (!nit) {
    return false
  }

  const nitRegExp = /^[0-9]+(-?[0-9kK])?$/

  if (!nitRegExp.test(nit)) {
    return false
  }

  nit = nit.replace(/-/, '')
  const lastChar = nit.length - 1
  const number = nit.substring(0, lastChar)
  const expectedCheker = nit.substring(lastChar, lastChar + 1).toLowerCase()

  let factor = number.length + 1
  let total = 0

  for (let i = 0; i < number.length; i++) {
    const character = number.substring(i, i + 1)
    const digit = parseInt(character, 10)

    total += (digit * factor)
    factor = factor - 1
  }

  const modulus = (11 - (total % 11)) % 11
  const computedChecker = (modulus === 10 ? 'k' : modulus.toString())

  return expectedCheker === computedChecker
}

export const transformDataToUpperCase = (data: any): any => {
  if (typeof data === 'string') {
    const isMongoId = data.match(/^[0-9a-fA-F]{24}$/)
    const isUUIDv4 = validate(data)
    const isBoolean = data === 'true' || data === 'false' || typeof data === 'boolean'
    if (isBoolean) return Boolean(data)
    if (isMongoId || isUUIDv4) return data
    return data.toUpperCase()
  } else if (Array.isArray(data)) {
    return data.map(transformDataToUpperCase)
  } else if (typeof data === 'object' && data !== null) {
    return Object.keys(data).reduce((newData, key) => {
      newData[key] = transformDataToUpperCase(data[key])
      return newData
    }, {})
  } else {
    return data
  }
}

export const transformFirstLetterToUpperCase = (text: string): string => {
  if (!text) return ''
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function focusAndScroll (id: string): void {
  const element = document.getElementById(id)
  if (element) {
    const rect = element.getBoundingClientRect()
    const isVisible = rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    if (!isVisible) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    element.classList.add('focus')
    setTimeout(() => { element.classList.remove('focus') }, 3000)
  }
}

export const formatNumberLocal = new Intl.NumberFormat(LOCALE_CURRENCY, {
  style: 'currency',
  currency: DEFAULT_CURRENCY.code
})
