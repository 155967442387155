import { z } from 'zod'

export const dataProperties = {
  id: '_id',
  changeId: 'db_id',
  uid: 'id',
  disabled: 'disabled',
  default: 'default',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  updatedBy: 'updatedBy',
  createdBy: 'createdBy'
} as const

export const zodStringTransform = z.string().toUpperCase().trim()
