import AuthProvider from './features/auth/context/auth-provider'
import { Toaster } from 'sonner'
import { ThemeProvider } from './components/theme-provider'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import '@/assets/global.css'
import { Suspense, lazy } from 'react'
import { BrowserRouter, Navigate, Route } from 'react-router-dom'
import { TypeAccount } from './features/users/models/user'
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from './router/paths'
import RoutesWithNotFound from './components/routes-with-not-found'
import AuthRouter from './features/auth/router/auth-router'
import LoadingBrand from './components/loading-brand'
import RequireAuth from './features/auth/components/require-auth'
import PersistLogin from './features/auth/components/persist-login'
import { TailwindIndicator } from './components/tailwind-indicator'

const RouterAdmin = lazy(async () => await import('./router/router-admin'))

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 5,
      retryDelay: 1000
    }
  }
})

function App () {
  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <Toaster
          position='top-right'
          richColors
          closeButton
          expand={false}
        />
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <Suspense fallback={<LoadingBrand className=' h-screen w-screen' />}>
              <BrowserRouter>
                <RoutesWithNotFound>
                  <Route path='/' element={<Navigate to={PRIVATE_ROUTE.ADMIN} />} />
                  <Route path={`${PUBLIC_ROUTE.AUTH}/*`} element={<AuthRouter />} />
                  <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth allowedAccounts={[TypeAccount.admin, TypeAccount.user, TypeAccount.advisor]} />}>
                      <Route path={`${PRIVATE_ROUTE.ADMIN}/*`} element={<RouterAdmin />} />
                    </Route>
                  </Route>
                </RoutesWithNotFound>
              </BrowserRouter>
            </Suspense>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} position='bottom' buttonPosition='bottom-left' />
          <TailwindIndicator />
        </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
