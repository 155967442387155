export const TYPE_ERRORS = {
  ERROR_CLIENT_EXIST: 'ERROR_CLIENT_EXIST',
  USER_OR_PASSWORD_INVALID: 'USER_OR_PASSWORD_INVALID',
  USER_OR_EMAIL_INVALID: 'USER_OR_EMAIL_INVALID',
  USER_INACTIVE: 'USER_INACTIVE',
  ERROR_REQUEST: 'ERROR_REQUEST',
  ACCESS_DENIED: 'ACCESS_DENIED',
  PASSWORD_NOT_MATCH: 'PASSWORD_NOT_MATCH',
  ERROR_NOT_DELETE_DEFAULT: 'ERROR_NOT_DELETE_DEFAULT',
  GENERIC_ERROR: 'GENERIC_ERROR',
  PASSWORD_NOT_VALID: 'PASSWORD_NOT_VALID',
  // Workflow
  ERROR_WORKFLOW_NOT_FOUND: 'ERROR_WORKFLOW_NOT_FOUND',
  ERROR_NOT_UPDATE_ACTION_DEFAULT: 'ERROR_NOT_UPDATE_ACTION_DEFAULT',
  ERROR_NOT_DELETE_BLOCKED: 'ERROR_NOT_DELETE_BLOCKED',
  // COMPANIES
  ERROR_COMPANY_EXIST: 'ERROR_COMPANY_EXIST',
  ERROR_COMPANY_NOT_DELETE_DEFAULT: 'ERROR_COMPANY_NOT_DELETE_DEFAULT',
  ERROR_COMPANY_NOT_DELETE_BLOCKED: 'ERROR_COMPANY_NOT_DELETE_BLOCKED',
  ERROR_COMPANY_NOT_DELETE: 'ERROR_COMPANY_NOT_DELETE',
  ERROR_COMPANY_NOT_DEFAULT_CVN: 'ERROR_COMPANY_NOT_DEFAULT_CVN',
  ERROR_COMPANY_NOT_INACTIVE: 'ERROR_COMPANY_NOT_INACTIVE',
  ERROR_COMPANY_NOT_INACTIVE_DEFAULT: 'ERROR_COMPANY_NOT_INACTIVE_DEFAULT',
  ERROR_COMPANY_NOT_FOUND: 'ERROR_COMPANY_NOT_FOUND',
  // SOCIETIES
  ERROR_SOCIETY_EXIST: 'ERROR_SOCIETY_EXIST',
  ERROR_SOCIETY_NOT_FOUND: 'ERROR_SOCIETY_NOT_FOUND',
  // PROJECTS
  ERROR_PROJECT_EXIST: 'ERROR_PROJECT_EXIST',
  ERROR_PROJECT_NOT_FOUND: 'ERROR_PROJECT_NOT_FOUND',
  // PROPERTIES
  ERROR_PROPERTY_EXIST: 'ERROR_PROPERTY_EXIST',
  ERROR_PROPERTY_NOT_FOUND: 'ERROR_PROPERTY_NOT_FOUND',
  // FILES
  ERRRO_FILE_MAX_SIZE_EXCEEDED: 'ERRRO_FILE_MAX_SIZE_EXCEEDED',
  ERROR_UPLOAD_PHOTO_CLIENT: 'ERROR_UPLOAD_PHOTO_CLIENT',
  // UNAUTHORIZED
  UNAUTHORIZED: 'UNAUTHORIZED',
  ERROR_NOT_ALLOWED: 'ERROR_NOT_ALLOWED',
  // 2FA
  TWO_FACTOR_AUTHENTICATION_CODE_INVALID: 'TWO_FACTOR_AUTHENTICATION_CODE_INVALID',
  // NEGOTIATIONS
  ERROR_NEGOTIATION_NOT_FOUND: 'ERROR_NEGOTIATION_NOT_FOUND',
  ERROR_CREATE_NEGOTIATION_BY_PROPERTY_WITH_NEGOTIATION_ACTIVE: 'ERROR_CREATE_NEGOTIATION_BY_PROPERTY_WITH_NEGOTIATION_ACTIVE',
  ERROR_NEGOTIATION_IS_STATE_INACTIVE: 'ERROR_NEGOTIATION_IS_STATE_INACTIVE',
  ERROR_NEGOTIATION_IS_NOT_STATE_INACTIVE: 'ERROR_NEGOTIATION_IS_NOT_STATE_INACTIVE',
  ERROR_STATE_PROCESS_ASSIGN_BUT_WORKFLOW_NOT: 'ERROR_STATE_PROCESS_ASSIGN_BUT_WORKFLOW_NOT',
  ERROR_NEXT_STATE_NOT_FOUND: 'ERROR_NEXT_STATE_NOT_FOUND',
  ERROR_NEGOTIATION_NOT_HAVE_WORKFLOW: 'ERROR_NEGOTIATION_NOT_HAVE_WORKFLOW',
  ERROR_NEGOTIATION_IS_NOT_STATE_PROCESS: 'ERROR_NEGOTIATION_IS_NOT_STATE_PROCESS',
  ERROR_NEGOTIATION_IS_STATE_RESCIND: 'ERROR_NEGOTIATION_IS_STATE_RESCIND',
  ERROR_NEGOTIATION_IS_STATE_END: 'ERROR_NEGOTIATION_IS_STATE_END',
  ERROR_NEGOTIATION_DEFAULT_OWNER_NOT_FOUND: 'ERROR_NEGOTIATION_DEFAULT_OWNER_NOT_FOUND',
  ERROR_NEGOTIATION_STATE_IS_INIT_OR_NOT_WORKFLOW_ASSIGN: 'ERROR_NEGOTIATION_STATE_IS_INIT_OR_NOT_WORKFLOW_ASSIGN',
  ERROR_NEGOTIATION_PAYMENT_AGREEMENT_TOTAL_AMOUNT_NOT_FOUND: 'ERROR_NEGOTIATION_PAYMENT_AGREEMENT_TOTAL_AMOUNT_NOT_FOUND',
  // SAP
  ERROR_SAP_LOGIN: 'ERROR_SAP_LOGIN',
  ERROR_SAP_WRONG_CREDENTIALS: 'ERROR_SAP_WRONG_CREDENTIALS',
  ERROR_SAP_GET_OP_DEPENDENCIES: 'ERROR_SAP_GET_OP_DEPENDENCIES',
  ERROR_SAP_GET_OP_LEVEL: 'ERROR_SAP_GET_OP_LEVEL',
  ERROR_SAP_GET_OP_LEVEL_NOT_VALID: 'ERROR_SAP_GET_OP_LEVEL_NOT_VALID',
  ERROR_SAP_GET_ORDER_PRODUCTION: 'ERROR_SAP_GET_ORDER_PRODUCTION',
  ERROR_SAP_UPDATE_ORDER_PRODUCTION: 'ERROR_SAP_UPDATE_ORDER_PRODUCTION',
  ERROR_SAP_CREATE_ORDER_PRODUCTION: 'ERROR_SAP_CREATE_ORDER_PRODUCTION',
  ERROR_SAP_GET_ORDER_PRODUCTION_NOT_FOUND: 'ERROR_SAP_GET_ORDER_PRODUCTION_NOT_FOUND',
  ERROR_SAP_ORDER_PRODUCTION_WITHOUT_DEPENDENCIES: 'ERROR_SAP_ORDER_PRODUCTION_WITHOUT_DEPENDENCIES',
  ERROR_SAP_CREATE_BUSINESS_PARTNER: 'ERROR_SAP_CREATE_BUSINESS_PARTNER',
  ERROR_SAP_OPERATION: 'ERROR_SAP_OPERATION',
  ERROR_SAP_INVALID_BUSINESS_PARTNER_TYPE: 'ERROR_SAP_INVALID_BUSINESS_PARTNER_TYPE',
  ERROR_SAP_BUSINESS_PARTNER_NOT_FOUND: 'ERROR_SAP_BUSINESS_PARTNER_NOT_FOUND',
  ERROR_SAP_ORDER_PRODUCTION_NOT_FOUND: 'ERROR_SAP_ORDER_PRODUCTION_NOT_FOUND',
  ERROR_SAP_CREATE_ORDER: 'ERROR_SAP_CREATE_ORDER',
  ERROR_SAP_ASSIGN_PAYMENT_PLAN_TO_ORDER: 'ERROR_SAP_ASSIGN_PAYMENT_PLAN_TO_ORDER',
  ERROR_SAP_UPDATE_ORDER: 'ERROR_SAP_UPDATE_ORDER',
  ERROR_SAP_ORDER_NOT_FOUND: 'ERROR_SAP_ORDER_NOT_FOUND',
  ERROR_SAP_UPDATE_PAYMENT_PLAN: 'ERROR_SAP_UPDATE_PAYMENT_PLAN',
  ERROR_SAP_REMOVE_PAYMENT_PLAN: 'ERROR_SAP_REMOVE_PAYMENT_PLAN',
  ERROR_SAP_ORDER_BUSINESS_PARTNER_NOT_CAN_BE_UPDATED: 'ERROR_SAP_ORDER_BUSINESS_PARTNER_NOT_CAN_BE_UPDATED',
  // RENAP
  RENAP_ERROR: 'RENAP_ERROR',
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS',
  TOKEN_INVALID: 'TOKEN_INVALID',
  INVALID_CUI: 'INVALID_CUI',
  NO_MATCH: 'NO_MATCH'
} as const

export const CODE_ERRORS = {
  // CLIENTS
  ERROR_CLIENT_EXIST: 'El cliente ya existe en el sistema con el dato de identificación proporcionado. Sugerimos realizar una búsqueda en el sistema para encontrar el cliente existente o verificar la información ingresada.',
  USER_OR_PASSWORD_INVALID: 'Usuario o contraseña incorrectos',
  USER_OR_EMAIL_INVALID: 'Usuario o correo electrónico incorrectos',
  USER_INACTIVE: 'Usuario inactivo',
  NOT_FOUND: 'No se encontró el recurso solicitado',
  ERROR_REQUEST: 'Ocurrió un error al realizar la solicitud, intente nuevamente más tarde',
  ACCESS_DENIED: 'Error de acceso',
  PASSWORD_NOT_MATCH: 'Las contraseñas no coinciden',
  ERROR_NOT_DELETE_DEFAULT: 'No se puede eliminar un registro por defecto, asegurese de asignar a otro registro como por defecto',
  // GENERICS
  GENERIC_ERROR: 'Ocurrió un error, intente nuevamente más tarde',
  LOGIN_ERROR: 'Ocurrió un error al iniciar sesión, intente nuevamente más tarde',
  // Workflow
  ERROR_WORKFLOW_NOT_FOUND: 'No se puede realizar esta acción porque el flujo de trabajo no se encuentra asignado',
  ERROR_NOT_UPDATE_ACTION_DEFAULT: 'No se puede cambiar la acción de un registro por defecto, asegurese de asignar a otro registro como por defecto',
  ERROR_NOT_DELETE_BLOCKED: 'No se puede eliminar un registro bloqueado',
  // COMPANIES
  ERROR_COMPANY_EXIST: 'Ya existe una empresa con el mismo nombre',
  ERROR_COMPANY_NOT_DELETE_DEFAULT: 'No se puede eliminar una empresa por defecto, asegurese de asignar a otra empresa como por defecto',
  // SOCIETIES
  ERROR_SOCIETY_EXIST: 'Ya existe una sociedad con el mismo nombre',
  ERROR_SOCIETY_NOT_FOUND: 'No se puedo encontrar la sociedad seleccionada',
  // PROJECTS
  ERROR_PROJECT_EXIST: 'Ya existe un proyecto con el mismo nombre o código',
  ERROR_PROJECT_NOT_FOUND: 'No se puedo encontrar el proyecto seleccionado',
  // PROPERTIES
  ERROR_PROPERTY_EXIST: 'Ya existe un inmueble con el mismo nombre o código',
  ERROR_PROPERTY_NOT_FOUND: 'No se puedo encontrar el inmueble seleccionado',
  // FILES
  ERRRO_FILE_MAX_SIZE_EXCEEDED: 'El archivo excede el tamaño máximo permitido',
  ERROR_UPLOAD_PHOTO_CLIENT: 'Error al subir la foto del cliente',
  // UNAUTHORIZED
  UNAUTHORIZED: 'No autorizado',
  ERROR_NOT_ALLOWED: 'No tienes los permisos necesarios para realizar esta acción',
  // 2FA
  TWO_FACTOR_AUTHENTICATION_CODE_INVALID: 'Código de autenticación inválido',
  // NEGOTIATIONS
  ERROR_NEGOTIATION_NOT_FOUND: 'No se puede encontrar la negociación seleccionada',
  ERROR_CREATE_NEGOTIATION_BY_PROPERTY_WITH_NEGOTIATION_ACTIVE: 'No se puede crear una nueva negociación para un inmueble con una negociación activa',
  ERROR_UPDATE_NEGOTIATION_WITH_NEGOTIATION_ACTIVE: 'No se puede actualizar la negociación por que el inmueble tiene una negociación activa',
  ERROR_NEGOTIATION_IS_STATE_INACTIVE: 'No se puede realizar esta acción porque la negociación está inactiva',
  ERROR_NEGOTIATION_IS_NOT_STATE_INACTIVE: 'No se puede realizar esta acción porque la negociación está activa',
  ERROR_STATE_PROCESS_ASSIGN_BUT_WORKFLOW_NOT: 'No se puede realizar esta acción porque el flujo de trabajo no se encuentra asignado',
  ERROR_NEXT_STATE_NOT_FOUND: 'No se puede realizar esta acción porque el siguiente estado no se encuentra',
  ERROR_NEGOTIATION_NOT_HAVE_WORKFLOW: 'No se puede realizar esta acción porque la negociación no tiene un flujo de trabajo asignado',
  ERROR_NEGOTIATION_IS_NOT_STATE_PROCESS: 'No se puede realizar esta acción porque la negociación no se encuentra en un estado de proceso',
  ERROR_NEGOTIATION_IS_STATE_RESCIND: 'No se puede realizar esta acción porque la negociación se encuentra en un estado de rescisión',
  ERROR_NEGOTIATION_IS_STATE_END: 'No se puede realizar esta acción porque la negociación se encuentra en un estado de finalizado',
  ERROR_NEGOTIATION_DEFAULT_OWNER_NOT_FOUND: 'No se puede realizar esta acción porque el propietario por defecto no se encuentra',
  ERROR_NEGOTIATION_STATE_IS_INIT_OR_NOT_WORKFLOW_ASSIGN: 'No se puede realizar esta acción porque el estado no es inicial o no tiene un flujo de trabajo asignado',
  ERROR_NEGOTIATION_PAYMENT_AGREEMENT_TOTAL_AMOUNT_NOT_FOUND: 'No se puede realizar esta acción porque el total de la cuota no se encuentra',
  // SAP
  ERROR_SAP_LOGIN: 'Error al iniciar sesión en SAP',
  ERROR_SAP_WRONG_CREDENTIALS: 'Credenciales incorrectas para iniciar sesión en SAP',
  ERROR_SAP_GET_OP_DEPENDENCIES: 'Error al obtener las dependencias de SAP',
  ERROR_SAP_GET_OP_LEVEL: 'Error al obtener los niveles de SAP',
  ERROR_SAP_GET_OP_LEVEL_NOT_VALID: 'Error al obtener los niveles de SAP',
  ERROR_SAP_GET_ORDER_PRODUCTION: 'Error al obtener la producción de SAP',
  ERROR_SAP_UPDATE_ORDER_PRODUCTION: 'Error al actualizar la producción de SAP',
  ERROR_SAP_CREATE_ORDER_PRODUCTION: 'Error al crear la producción de SAP',
  ERROR_SAP_GET_ORDER_PRODUCTION_NOT_FOUND: 'No se encontró la producción en SAP',
  ERROR_SAP_ORDER_PRODUCTION_WITHOUT_DEPENDENCIES: 'No se puede realizar esta acción porque la producción no tiene dependencias',
  ERROR_SAP_CREATE_BUSINESS_PARTNER: 'Error al crear el cliente en SAP',
  ERROR_SAP_OPERATION: 'Error al realizar la operación en SAP',
  ERROR_SAP_INVALID_BUSINESS_PARTNER_TYPE: 'El tipo de cliente no es valido para crear en SAP',
  ERROR_SAP_BUSINESS_PARTNER_NOT_FOUND: 'No se encontró el cliente en SAP',
  ERROR_SAP_ORDER_PRODUCTION_NOT_FOUND: 'No se encontró la orden de producción en SAP',
  ERROR_SAP_CREATE_ORDER: 'Error al crear la orden en SAP',
  ERROR_SAP_ASSIGN_PAYMENT_PLAN_TO_ORDER: 'Error al asignar el plan de pago a la orden en SAP',
  ERROR_SAP_UPDATE_ORDER: 'Error al actualizar la orden en SAP',
  ERROR_SAP_ORDER_NOT_FOUND: 'No se encontró la orden en SAP',
  ERROR_SAP_UPDATE_PAYMENT_PLAN: 'Error al actualizar el plan de pago en SAP',
  ERROR_SAP_REMOVE_PAYMENT_PLAN: 'Error al eliminar el plan de pago en SAP',
  ERROR_SAP_ORDER_BUSINESS_PARTNER_NOT_CAN_BE_UPDATED: 'No se puede actualizar el cliente de la orden porque ya tiene un cliente asignado, para poder actualizar el cliente, se debe eliminar la orden y crear una nueva',
  // RENAP
  RENAP_ERROR: 'Error al realizar la operación en RENAP',
  WRONG_CREDENTIALS: 'Las credenciales de acceso a RENAP son incorrectas',
  TOKEN_INVALID: 'No tienes accesos al servicio de RENAP (Token invalido)',
  INVALID_CUI: 'El número de identificación es invalido',
  NO_MATCH: 'No se encontro ningún resultado'
} as const
